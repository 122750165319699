import { Link as GatsbyLink } from 'gatsby';
import styled from '@emotion/styled';
// import { desktopDown, mobile } from '@/app/common/style/mixins';
// import { COLOR } from '@/app/common/style/variables';

export const Wrapper = styled.div`
  ul {
    display: block;
    margin: 0;
    padding: 0;

    > li {
      margin: 0;
    }
  }
`;

export const Link = styled(GatsbyLink)``;
