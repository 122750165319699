import React, { FC } from 'react';

import { Link, Wrapper } from './styles';
import { ContentfulSection } from '../types.d';
import { ContentfulLink } from '@/types';

export interface LinksSection extends ContentfulSection {
  links: ContentfulLink[];
}

/**
 * Links section
 */
const Links: FC<LinksSection> = ({ links }) => (
  <Wrapper>
    <ul>
      {links.map((link, index) => (
        <li key={`${link.id}-${index}`}>
          <Link to={link.url} target={link.target}>
            {link.title}
          </Link>
        </li>
      ))}
    </ul>
  </Wrapper>
);

export default Links;
